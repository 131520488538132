<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col md="8">
        <h1>PHOTOS</h1>
      </v-col>

      <v-col md="4" class="text-right">
        <v-btn
          v-if="canpost === 'true'"
          color="primary"
          dark
          @click="dialog = true"
          >ADD PHOTO
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 cursor-pointer"
    >
      <template v-slot:item.thumbnail="{ item }">
        <img style="width: 100px" :src="item.thumbnail" />
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-container>
          <div class="popup-wrap">
            <div class="d-flex justify-space-between">
              <div class="popup-title">
                <h6>Add Photo</h6>
              </div>

              <v-btn text @click.native="dialog = false">
                <v-img
                  src="../../assets/close-icon.png"
                  alt="close-icon"
                ></v-img>
              </v-btn>
            </div>

            <div class="popup-wrap-inner step-one">
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-row class="px-5">
                    <v-col cols="12" sm="12">
                      <v-row>
                        <v-col cols="12">
                          <div
                            class="image-input"
                            :style="{
                              'background-image': `url(${addPhoto.thumbnail})`,
                            }"
                            @click="chooseImage"
                          >
                            <span
                              v-if="!addPhoto.thumbnail"
                              class="placeholder"
                            >
                              <v-icon class="camera-icon">mdi-camera</v-icon>
                              Choose an Image
                            </span>
                            <input
                              class="file-input"
                              ref="fileInput"
                              type="file"
                              @input="onSelectFile"
                            />
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-select
                            label="Type"
                            required
                            :items="photoTypeValue"
                            v-model="addPhoto.type"
                            hide-details
                          >
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            label="Comments"
                            required
                            outlined
                            v-model="addPhoto.comments"
                            hide-details
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div class="text-right">
                            <v-btn color="primary" dark @click="saveFormData">
                              Add
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayPhotos",
  components: { CustomerInfo },
  data() {
    return {
      canpost: localStorage.getItem("canpost"),
      dialog: false,
      valid: false,
      headers: [
        { text: "Type", value: "type" },
        { text: "Taken By", value: "takenBy" },
        { text: "Comments", value: "comments" },
        { text: "Thumbnail", value: "thumbnail" },
      ],
      items: [
        {
          type: "Project",
          takenBy: "CarlosL",
          comments: "Driveway damage before starting",
          thumbnail: "",
        },
        {
          type: "Project",
          takenBy: "CarlosL",
          comments: "Driveway damage before starting",
          thumbnail: "",
        },
      ],
      photoTypeValue: ["JPG", "JPEG", "PNG"],
      addPhoto: {
        type: "",
        takenBy: "CarlosL",
        comments: "",
        thumbnail: "",
      },
    };
  },
  methods: {
    saveFormData() {
      if (this.$refs.form.validate()) {
        this.items.push(this.addPhoto);
        this.dialog = false;
        this.addPhoto = {
          type: "",
          takenBy: "CarlosL",
          comments: "",
          thumbnail: "",
        };
      }
    },

    chooseImage() {
      this.$refs.fileInput.click();
    },

    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.addPhoto.thumbnail = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },
  },
};
</script>

<style scoped>
.popup-title {
  padding: 0px 18px;
  text-align: center;
}

.popup-wrap {
  padding: 20px 0;
}

.image-input {
  margin: auto;
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  color: #0000008a;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.camera-icon {
  font-size: 75px;
}
</style>
